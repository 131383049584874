import { DOCUMENT } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs';
import { ContenidoService } from 'src/app/core/services/contenido.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {

  ContentData: any = [];

  nombre: string = '';
  email: string = '';
  telefono: string = '';
  check: boolean = false;
  Form: FormGroup;
  responseEmail: number = 0;
  viewPdf: boolean = false;
  pdfSrc: string =  `https://application.infinitumscitech.co:900/uploads/Politica_de_Tratamiento_de_Datos_Personales_6dbb36a40f.pdf`;


  constructor(private srv: ContenidoService, private FB: FormBuilder) {
    this.srv.loading = true;
    this.Form = this.FB.group({
      nombre: ['', Validators.required],
      telefono: ['', [Validators.required]],
      empresa: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mensaje: ['']
    });
  }

  get loading() {
    return this.srv.loading;
  }

  navigateToSection(path: string) {
    history.pushState({}, '', path);
    const sectionId = path.substring(1);
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: 'smooth' });
  }

  @HostListener('window:popstate', ['$event'])
  popstate($event: any) {
    const sectionId = window.location.pathname.substring(1);
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: 'smooth' });
  }


  ngOnInit() {
    this.srv.obtenerdatos().pipe(

    ).subscribe((data: any) => {

      this.srv.loading = false;
      if (data.status) {
        this.ContentData = data.data;
      }
    });

  }

  get Header() {
    return this.ContentData.header == null ? null : this.ContentData.header[0];
  }

  get Detalles() {
    return this.ContentData.detalles;
  }

  get Caracteristicas() {
    return this.ContentData.caracteristicas;
  }

  get Testimonios() {
    return this.ContentData.testimonios;
  }

  esPar(numero: number) {
    return (numero % 2) == 0;
  }

  get isValid() {
    return this.nombre != '' && this.email != '' && this.telefono != '' && this.check == true ? true : false;
  }


  get FormValid(): boolean {
    if (this.check == false) {
      return false;
    }
    return this.Form.invalid ? false : true;
  }

  async submit() {

    try {
      this.srv.loading = true;
      this.responseEmail = 0;
      const data = {
        nombre: this.Form.controls['nombre'].value,
        empresa: this.Form.controls['empresa'].value,
        email: this.Form.controls['email'].value,
        telefono: this.Form.controls['telefono'].value,
        mensaje: this.Form.controls['mensaje'].value
      };
      const response: any = await this.srv.formulario(data).toPromise();
      console.log("EMAIL", response);
      this.Form.reset();

      this.srv.loading = false;
      if (response.status) {
        this.responseEmail = 1;
      } else {
        this.responseEmail = -1;
      }

    } catch (error) {
      this.srv.loading = false;
      this.responseEmail = -1;
      console.log(error);
    }

  }

  terminos() {
    this.check = !this.check;
  }


}
