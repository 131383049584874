import { Injectable } from '@angular/core';
import { AuthService } from './authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContenidoService {

  public loading: boolean = true;
  public menus: any = [];

  URL_API: string = `${environment.endpointWC}/api/get_data_container`;
  URL_FORM: string = `${environment.endpointWC}/api/form`;

  constructor(private Auth: AuthService) { }

  public get lang(): string {
    let response = localStorage.getItem(`${environment.appName}_lang`);
    if (response == null) {
      localStorage.setItem(`${environment.appName}_lang`, 'es');
    }
    return response ?? 'es';
  }

  public changeLang(lang: string) {
    localStorage.setItem(`${environment.appName}_lang`, lang);
  }

  obtenerdatos(inicial: boolean = false) {
    return this.Auth.consumirApiPOSTAuth(this.URL_API, { lang: this.lang, inicial });
  }

  formulario(data: any) {
    return this.Auth.consumirApiPOSTAuth(this.URL_FORM, data);
  }


}
