import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContenidoService } from 'src/app/core/services/contenido.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  constructor(private srv: ContenidoService, private translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.translocoService.setActiveLang(this.srv.lang);
  }

}
