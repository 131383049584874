import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PaginaComponent } from "./pagina.component";
import { BodyComponent } from "./body/body.component";

const routes: Routes = [
    {
        path: '',
        component: PaginaComponent,
        children: [
            { path: '', component: BodyComponent },
            { path: 'inicio', redirectTo: '' },
            { path: 'detalles', redirectTo: '' },
            { path: 'confian_en_nosotros', redirectTo: '' },
            { path: 'contactanos', redirectTo: '' },
            { path: 'terminos_y_condiciones', redirectTo: '' },
            { path: '**', redirectTo: '', pathMatch: 'full' }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PaginaRoutingModule { }

